.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*----------------------------------------------------------------------*/
/*NAVAGATION BAR*/
/*----------------------------------------------------------------------*/

#love {
  width: 20px;
}

#sticky {
  z-index: 2;
  width: 100%;
}

.content {
  margin-top: 20px;
}

.underline {
  background: none;
  box-sizing: border-box;
  color: #f45e61;
  font-size: inherit;
}
.underline::before {
  box-sizing: inherit;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
}

.underline:hover {
  color: #6477b9;
}
.underline::before {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -webkit-transform-origin: center;
  transform-origin: center;
}
.underline::before {
  border-bottom: 2px solid orange;
  -webkit-transform: scale3d(0, 1, 1);
  transform: scale3d(0, 1, 1);
}
.underline:hover::before {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}

@media (min-width: 979px) {
  ul.nav li.dropdown:hover > ul.dropdown-menu {
    display: inline-block;
  }
}

.navbar {
  position: relative;
  padding-top: 2vh;
  min-height: 7.5vh;
  -webkit-box-shadow: 0px 5px 50px 5px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 5px 50px 5px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 5px 50px 5px rgba(0, 0, 0, 0.28);
}

.navbar-brand {
  float: left;
  height: 50px;
  padding: 13px 15px;
  font-size: 1.2em;
  line-height: 20px;
}

@media (min-width: 768px) {
  .navbar {
    display: grid;
    justify-items: center;
    align-items: center;
    border: 1px solid transparent;
    font-size: 1.2em;
    padding: 0;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .pushed {
    padding-top: 10vh;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
  .navbar-brand {
    padding: 10px 15px;
    font-size: 1.3em;
  }

  .pushed {
    padding-top: 10vh;
  }
}
@media (min-width: 1500px) {
  .navbar {
    font-size: 1.5em;
  }
}

body {
  background-color: #333333;
  background-image: url("../public/img/generic-bg.jpg");
}

footer {
  min-height: 15vh;
  background-color: #333;
  padding: 50px;
  color: white;
}

h1 {
  text-align: center;
  font-size: 3em;
  font-weight: bold;
  font-family: "Comfortaa", cursive;
}

a {
  color: maroon;
}

a:hover {
  color: orange;
  text-decoration: none;
}

strong {
  color: maroon;
}

.headerDiv {
  padding: 5px;
  text-align: center;
  font-weight: bold;
  font-family: "Comfortaa", cursive;
  -webkit-box-shadow: 0px 10px 15px 1px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 10px 15px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 10px 15px 1px rgba(0, 0, 0, 0.28);
  color: white;
  margin: 5vh 0;
}

.headerDiv h1 {
  margin-left: 10px;
  display: inline;
}

.headerDiv i {
  font-size: 2.5em;
}

/*----------------------------------------------------------------------*/
/*BANNER SETTINGS*/
/*----------------------------------------------------------------------*/

#banner {
  min-height: 100vh;
  background-color: #df8b00;
  background-image: url("../public/img/landing.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  justify-content: center;
  display: grid;
  align-items: center;
  color: white;
}

.heading {
  font-size: 90px;
  margin: 0px 0px;
  font-family: "Audiowide", sans-serif;
}
.dnd {
  margin-top: 0px;
  display: block;
  font-family: "Comfortaa", cursive;
}
.pushed {
  padding-top: 10vh;
}

@media (max-width: 1175px) {
  .pushed {
    padding-top: 13vh;
  }
  #banner {
    /* background-image: url("../public/img/landingMin.jpg"); */
  }
}

@media (max-height: 420px) {
  .pushed {
    padding-top: 16vh;
  }
  .navbar {
    margin-bottom: -10px !important;
  }
  #banner {
    /* background-image: url("../public/img/landing.jpg"); */
  }
}

/*----------------------------------------------------------------------*/
/*NAVAGATION BAR*/
/*----------------------------------------------------------------------*/

#love {
  width: 20px;
}

#sticky {
  z-index: 2;
  width: 100%;
}

.content {
  margin-top: 20px;
}

.underline {
  background: none;
  box-sizing: border-box;
  color: #f45e61;
  font-size: inherit;
}
.underline::before {
  box-sizing: inherit;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
}

.underline:hover {
  color: #6477b9;
}
.underline::before {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -webkit-transform-origin: center;
  transform-origin: center;
}
.underline::before {
  border-bottom: 2px solid orange;
  -webkit-transform: scale3d(0, 1, 1);
  transform: scale3d(0, 1, 1);
}
.underline:hover::before {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}

@media (min-width: 979px) {
  ul.nav li.dropdown:hover > ul.dropdown-menu {
    display: inline-block;
  }
}

.navbar {
  font-family: "Comfortaa", cursive;
  position: relative;
  padding-top: 2vh;
  min-height: 7.5vh;
  -webkit-box-shadow: 0px 5px 50px 5px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 5px 50px 5px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 5px 50px 5px rgba(0, 0, 0, 0.28);
}

.navbar-brand {
  float: left;
  height: 50px;
  padding: 13px 15px;
  font-size: 1.2em;
  line-height: 20px;
}

@media (min-width: 768px) {
  .navbar {
    display: grid;
    justify-items: center;
    align-items: center;
    border: 1px solid transparent;
    font-size: 1.2em;
    padding: 0;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .pushed {
    padding-top: 10vh;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
  .navbar-brand {
    padding: 10px 15px;
    font-size: 1.3em;
  }

  .pushed {
    padding-top: 10vh;
  }
}
@media (min-width: 1500px) {
  .navbar {
    font-size: 1.5em;
  }
}

/*----------------------------------------------------------------------*/
/*Modal Contact Window [Navbar]*/
/*----------------------------------------------------------------------*/
@media (min-width: 600px) {
  .modal-content {
    padding-left: 50px;
    padding-right: 50px;
    font-size: 1.5em;
    font-family: "Comfortaa", cursive;
  }
}
@media (max-width: 600px) {
  .modal-content {
    padding-left: 50px;
    padding-right: 50px;
    font-size: 1.2em;
    font-family: "Comfortaa", cursive;
  }
}
@media (max-width: 500px) {
  .modal-content {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 1em;
    font-family: "Comfortaa", cursive;
  }
}

/*----------------------------------------------------------------------*/
/*About Me [Home Page]*/
/*----------------------------------------------------------------------*/

#aboutMe {
  background-image: url("../public/img/waves2.png");
  background-size: cover;
  overflow: hidden;
  width: 100%;
}

.aboutMeSummary {
  grid-area: bio;
  text-indent: 20px;
  padding: 10px;
  border-left: 2px solid rgb(52 156 217);
  font-size: 17px;
  align-self: top;
  height: fit-content;
  font-family: "Comfortaa", cursive;
}

.aboutSection {
  margin: 0px;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-bottom: 5vh;
  color: white;
  background-color: #255670ed;
  font-family: "Comfortaa", cursive;
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: auto;
  grid-gap: 1em;
  grid-template-areas:
    ". title title title title title title title title title ."
    ". img img img . . . spec spec spec ."
    ". img img img . stack stack stack xx . ."
    ". bio bio bio . stack stack stack xx . ."
    ". bio bio bio . . . love love love ."
    ". . . . . . . . . . .";
}

.asTitle {
  grid-area: title;
}
.asImg {
  grid-area: img;
  max-width: 300px;
  max-height: 300px;
  justify-self: center;
}

#me {
  background-color: black;
  border-color: black;
}

.specialize {
  grid-area: spec;
  display: grid;
  margin: auto;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  justify-items: center;
  grid-column-gap: 1em;
  height: auto;
  max-width: 300px;
}
.specHeader {
  grid-column: 1 / 4;
}

.myStack {
  grid-area: stack;
  display: grid;
  align-self: center;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
}
.stackHeader {
  grid-column: 1 / 4;
  grid-row-start: 1;
  align-self: start;
  justify-self: start;
}
.stackImg {
  grid-column: 1 / 4;
  grid-row-start: 1;
  padding-top: 15px;
}

.stackImg img {
  padding-top: 15px;
  max-height: 209px;
}

.likes {
  grid-area: love;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 1em;
  max-width: 500px;
}
.likesHeader {
  grid-column: 2 / 4;
  grid-row-start: 1;
  align-self: start;
  justify-self: end;
}
.likesHeader h2 {
  margin: 0;
}
.likesImg {
  grid-column: 1 / 4;
  grid-row-start: 1;
  align-self: start;
  justify-self: start;
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .aboutSection {
    grid-template-areas:
      ". title title title title title title title title title ."
      ". img img img . . . spec spec spec ."
      ". img img img . stack stack stack . . ."
      ". bio bio bio . stack stack stack . . ."
      ". bio bio bio . . . love love love .";
  }
}

@media (max-width: 768px) {
  .aboutSection {
    font-family: "Comfortaa", cursive;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 2em;
    grid-template-areas:
      "title"
      "img"
      "bio "
      "spec"
      "stack"
      "love"
      ".";
  }
  .specialize {
    grid-area: spec;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    justify-items: center;
    grid-column-gap: 1em;
    height: auto;
    max-width: 300px;
  }
  .myStack {
    margin-bottom: 5vh;
    grid-area: stack;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
  }
}

/*----------------------------------------------------------------------*/
/*What I do Section [Home Page]*/
/*----------------------------------------------------------------------*/

.whatIDoSection {
  background-image: url("../public/img/moonwaves.jfif");
  /* background-color: white; */
  background-size: cover;
  background-position: center;
  text-align: center;
  margin: 0px;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-bottom: 5vh;
  font-family: "Comfortaa", cursive;
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: auto;
  grid-gap: 1em;
  grid-template-areas:
    ". title title title title title title title title title ."
    ". body body body body body body body body body .";
}
.widTitle {
  grid-area: title;
  background-color: #2e5272e0;
}
.widBody {
  grid-area: body;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
}
.wdCard {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "text img";
}

.wdCard:nth-child(odd) {
  grid-template-areas: "img text";
}
.wdImg {
  grid-area: img;
}
.wdText {
  grid-area: text;
}

.wdFont {
  font-size: 17px;
}

.whatIDoSection img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10vh;
}

.whatIDoSection h1 {
  color: white;
  margin-top: 15%;
  margin-bottom: 5px;
}

.whatIDoSection .headerDiv {
  padding: 5px 0 0 0;
}
.whatIDoSection .headerDiv h1 {
  margin-top: 20px;
  color: rgb(255, 255, 255);
}
.whatIDoSection .headerDiv i {
  font-size: 2em;
}

.rocket-cog {
  margin-top: -20px;
}
.fa-wrench {
  color: rgba(36, 36, 36, 0.849);
}

@media (max-width: 768px) {
  .whatIDoSection {
    grid-template-columns: 1fr;
    grid-gap: 1em;
    grid-template-areas:
      "title"
      "body";
  }
  .wdCard {
    grid-template-columns: 1fr;
    grid-template-areas:
      "text"
      "img";
  }
  .wdCard:nth-child(odd) {
    grid-template-areas:
      "text"
      "img";
  }
}

/*----------------------------------------------------------------------*/
/*My Tools Section [Home Page]*/
/*----------------------------------------------------------------------*/

.toolsSection {
  background-color: white;
  text-align: center;
  margin: 0px;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-bottom: 5vh;
  font-family: "Comfortaa", cursive;
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: auto;
  /* grid-gap: 1vw; */
  grid-template-areas:
    ". title title title title title title title title title ."
    ". body body body body body body body body body .";
}

.toolsTitle {
  grid-area: title;
}
.toolsBody {
  grid-area: body;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-row-gap: 5em;
}

@media (max-width: 768px) {
  .toolsSection {
    grid-template-columns: 1fr;
    grid-template-areas:
      "title"
      "body";
  }

  .toolsBody {
    grid-row-gap: 2em;
    grid-column-gap: 1em;
    grid-template-columns: repeat(2, 1fr);
  }
}

.toolRow {
  margin: 5vh 0 10vh 0;
}

.toolsSection img {
  height: auto;
  max-width: 80%;
}

/*----------------------------------------------------------------------*/
/*Portfolio Section [Home Page]*/
/*----------------------------------------------------------------------*/

.portSection {
  background-image: url("../public/img/gray-bg.png");
  background-repeat: repeat;
  background-position: center;
  min-height: 60vh;
  background-color: #333;
  text-align: center;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-bottom: 10vh;
  font-family: "Comfortaa", cursive;
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    ". title title title title title title title title title ."
    ". body body body body body body body body body ."
    ". . . . . btn . . . . .";
}
.portTitle {
  grid-area: title;
}
.portBody {
  grid-area: body;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  /* grid-gap: 1em; */
}
.portBtn {
  grid-area: btn;
  display: grid;
  margin-top: 5vh;
  align-self: center;
}

@media (max-width: 768px) {
  .portSection {
    grid-template-columns: 1fr;
    grid-template-areas:
      "title"
      "body"
      "btn";
  }
  .portBody {
    grid-row-gap: 2em;
    grid-column-gap: 1em;
    grid-template-columns: 1fr;
  }
}

.portfolioThumbnail {
  margin: 10px;
  width: 90%;
  height: auto;
  -webkit-box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.28);
}

.portfolioThumbnail:hover {
  -webkit-box-shadow: 0px 0px 20px 5px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0px 0px 20px 5px rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 20px 5px rgba(255, 255, 255, 1);
}

.moreButton {
  -webkit-box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.28);
}

.headerSection {
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  padding-top: 20vh;
  min-height: 40vh;
  color: white;
  font-size: 2em;
  font-family: "Shadows Into Light", cursive;
}

.headerSection h1 {
  font-weight: bold;
  font-size: 3em;
}

@media (max-width: 979px) {
  .headerSection {
    padding-top: 15vh;
    min-height: 35vh;
    font-size: 1.5em;
  }
}

/*----------------------------------------------------------------------*/
/*Portfolio Pages*/
/*----------------------------------------------------------------------*/

#portfolioHeader {
  background-image: url("../public/img/portHeader.png");
}

#portfolioBody {
  overflow: hidden;
}

.portfolioMin {
  min-height: 60vh;
  text-align: center;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-bottom: 10vh;
  font-family: "Comfortaa", cursive;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    ". . . . . . . . . btnGrid ."
    ". . . . . . . . . . ."
    ". . body body body body body body body . ."
    ". . . . . . . . . . .";
}

.portfolioExp {
  min-height: 60vh;
  text-align: center;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-bottom: 10vh;
  font-family: "Comfortaa", cursive;
  justify-items: center;
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    ". . . . . . . . . btnGrid ."
    ". . . . . . . . . . ."
    ". body body body body body body body body body ."
    ". . . . . . . . . . .";
}
.pmBody {
  grid-area: body;
  display: grid;
  grid-row-gap: 2em;
  grid-template-columns: 1fr 1fr;
}
.pxBody {
  grid-area: body;
  display: grid;
  grid-row-gap: 0em;
  grid-template-columns: 1fr;
}
.pmBtnGrid {
  grid-area: btnGrid;
  display: grid;
  align-self: center;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: ". . btn";
}
.pmBtn {
  grid-area: btn;
}

.projectCard {
  margin: 5vh 0;
  padding: 5vh;
  background-color: white;
  border-bottom: 2px solid orange;
  font-family: "Comfortaa", cursive;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  grid-gap: 1em;
  grid-template-areas:
    "title title title title img img"
    "body body body body img img"
    "body body body body tools tools"
    "footer footer footer footer tools tools";
  -webkit-box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 10px 20px 55px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.28);
}

.projectCard:nth-child(odd) {
  grid-template-areas:
    "img img title title title title"
    "img img body body body body"
    "tools tools body body body body"
    "tools tools footer footer footer footer";
}

.projectCard .headerDiv {
  margin-top: 0px;
  margin-bottom: 2.5vh;
}

.projectTitle {
  grid-area: title;
}

.projectImg {
  grid-area: img;
}

.projectImg .portfolioThumbnail {
  margin: 0;
  width: 100%;
  height: auto;
}

.projectBody {
  grid-area: body;
}

.projectBody p {
  font-size: 18px;
  text-align: left;
  text-indent: 10px;
}

.projectTools {
  grid-area: tools;
}

.projectTools p {
  font-size: 14px;
  text-align: center;
}

.projectFooter {
  grid-area: footer;
  align-self: end;
}

@media (max-width: 768px) {
  .portfolioMin {
    grid-template-columns: 1fr;
    grid-template-areas:
      "btnGrid"
      "body";
  }

  .portfolioExp {
    grid-template-columns: 1fr;
    grid-template-areas:
      "btnGrid"
      "body";
  }
  .pmBody {
    grid-row-gap: 1em;
    grid-template-columns: 1fr;
  }

  .projectCard {
    align-items: center;
    grid-template-columns: 1fr;
    grid-gap: 2em;
    grid-template-areas:
      "title"
      "img"
      "body"
      "tools"
      "footer";
  }
  .projectCard:nth-child(odd) {
    align-items: center;
    grid-template-columns: 1fr;
    grid-gap: 2em;
    grid-template-areas:
      "title"
      "img"
      "body"
      "tools"
      "footer";
  }
  .projectImg {
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

/*----------------------------------------------------------------------*/
/*About Page*/
/*----------------------------------------------------------------------*/

#aboutHeader {
  background-image: url("../public/img/aboutHeader.png");
}

.aboutPage {
  padding: 5vh;
  width: auto;
  font-family: "Comfortaa", cursive;
  overflow: hidden;
  display: grid;
  grid-template-columns: 5fr 5fr;
  grid-template-rows: auto;
  grid-gap: 1em;
  grid-template-areas: "body likes";
}

.aboutBio {
  grid-area: body;
}

.aboutPanel {
  text-indent: 20px;
  background-color: white;
  width: auto;
  padding: 2.5vh 5vw;
  -webkit-box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 10px 20px 55px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.28);
  font-family: "Comfortaa", cursive;
  font-size: 2em;
}

.aboutLikes {
  grid-area: likes;
  width: auto;
  /* margin: auto; */
  font-family: "Comfortaa", cursive;
  display: grid;
  grid-template-columns: 5fr 5fr;
  grid-template-rows: auto;
  grid-gap: 1em;
}

.likesBlock {
  height: 200px;
  width: auto;
  border-left: 2px solid orange;
  overflow: hidden;
  color: white;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 10px 20px 55px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.28);
}

.likesBlock:nth-child(even) {
  margin-top: 100px;
}

.likesBlock:nth-child(odd) {
  background-color: black;
}

@media (max-width: 768px) {
  .aboutPage {
    z-index: -1;
    padding: 5vw;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 1em;
    grid-template-areas:
      "body"
      "likes";
  }

  .aboutPanel {
    text-indent: 10px;
    background-color: white;
    width: auto;
    padding: 2.5vh 5vw;
    font-size: 1em;
  }
}

/*----------------------------------------------------------------------*/
/*Blog Page*/
/*----------------------------------------------------------------------*/

#blogHeader {
  background-image: url("../public/img/blogHeader.png");
}
.blogContainer {
  margin: 5vh 0 10vh 0;
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-areas: ". body body body body body body body body body .";
}
.blogGrid {
  display: grid;
  grid-area: body;
  grid-template-columns: 1fr;
}
.blogCard {
  margin: 5vh 0;
  padding: 5vh;
  justify-items: center;
  background-color: white;
  border-bottom: 2px solid orange;
  font-family: "Comfortaa", cursive;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  grid-gap: 1em;
  grid-template-areas: "head head desc desc desc desc";
  -webkit-box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 10px 20px 55px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.28);
}
.blogHead {
  border-right: 2px solid orange;
  grid-area: head;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  justify-items: center;
  grid-gap: 1em;
  grid-template-areas:
    "title title title"
    ". img .";
}
.blogTitle {
  grid-area: title;
}
.blogImg {
  grid-area: img;
  max-height: 100px;
  max-width: 200px;
}
.postImg {
  grid-area: img;
}

.blogBody {
  grid-area: desc;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  justify-items: center;
  grid-gap: 1em;
  grid-template-areas:
    ". . . . . ."
    ". subs subs subs subs ."
    ". . . . date date";
}
.blogDate {
  grid-area: date;
}
.blogSubs {
  grid-area: subs;
}

.postGrid {
  display: grid;
  grid-area: body;
  grid-template-columns: repeat(11, 1fr);
  grid-template-areas: ". card card card card card card card card card .";
}
.postCard {
  grid-area: card;
  margin-top: 10vh;
  margin-bottom: 10vh;
  padding: 5vh;
  justify-items: center;
  background-color: white;
  border-bottom: 2px solid orange;
  font-family: "Comfortaa", cursive;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  grid-gap: 1em;
  grid-template-areas:
    "img img desc desc desc desc"
    "img img desc desc desc desc";
  -webkit-box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 10px 20px 55px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.28);
}
.postBody {
  grid-area: desc;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  grid-gap: 1em;
  grid-template-areas:
    "title title title title title title"
    "subs subs subs subs subs subs"
    ". . . . . ."
    ". . . . date date";
}

.blogPost {
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  border-bottom: 2px solid orange;
  width: auto;
  padding: 5vw;
  -webkit-box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 10px 20px 55px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.28);
  font-family: "Comfortaa", cursive;
}

.blogPost p {
  font-size: 14px;
}

@media (max-width: 768px) {
  .blogCard {
    padding: 5vw;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 1em;
    grid-template-areas:
      "head"
      "desc";
  }
  .blogHead {
    border-right: none;
    border-left: 2px solid orange;
  }

  .postCard {
    grid-area: card;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 1em;
    grid-template-areas:
      "img"
      "desc";
  }
}

.footerGrid {
  display: grid;
  grid-row-gap: 2em;
  grid-template-columns: 1fr;
}

/*----------------------------------------------------------------------*/
/*General |||| EVENTUALLY MERGE THIS SHIT */
/*----------------------------------------------------------------------*/

.fixed {
  position: fixed;
}

.inline {
  display: inline-block;
}

.centered {
  text-align: center;
}

.centerItems {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.vw30 {
  width: 30vw;
}
.vh30 {
  width: 30vw;
}

.tm-s {
  margin-top: 5vh;
}
.tm-xs {
  margin-top: 1.5vh;
}
.tm-l {
  margin-top: 20vh;
}
.bm-xs {
  margin-bottom: 5vh;
}
.bm-xxs {
  margin-bottom: 2.5vh;
}
.bm-s {
  margin-bottom: 10vh;
}
.bm-l {
  margin-bottom: 20vh;
}

.lm-s {
  margin-left: 5vw;
}
.lm-l {
  margin-left: 10vw;
}
.rm-s {
  margin-right: 5vw;
}
.rm-l {
  margin-right: 10vw;
}
.fullscreen {
  height: 75vh;
}

.shadow {
  -webkit-box-shadow: 0px 7px 12px 2px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 7px 12px 2px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 7px 12px 2px rgba(0, 0, 0, 0.28);
}

.btn {
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.28);
}

.contact-btn:focus {
  outline: none;
}

.tag {
  color: white;
  font-family: "Shadows Into Light", cursive;
}
.white-bg {
  background-color: white;
  color: #333333;
}
.orange-bg {
  background-color: #ffa501;
}
.black-bg {
  background-color: #333333;
}
.red-bg {
  background-color: #c84742;
}
.rentacar-bg {
  background-color: #b01aa7;
}
.green-bg {
  background-color: #007bff;
}

.font-2 {
  font-size: 2em;
  font-family: "Shadows Into Light", cursive;
}
.font-1 {
  font-size: 2em;
  font-family: "Comfortaa", cursive;
}

.textContentPanel {
  text-indent: 20px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  width: auto;
  padding: 2.5vh 5vw;
  -webkit-box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 10px 20px 55px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.28);
  font-family: "Comfortaa", cursive;
  font-size: 2em;
}

.myH1 {
  font-size: 3em;
  text-align: center;
  font-weight: bold;
  font-family: "Comfortaa", cursive;
}
.myH2 {
  font-size: 1.5em;
  text-align: center;
  font-weight: bold;
}

.noflow {
  overflow: hidden;
}

.highlight:hover {
  -webkit-box-shadow: 0px 0px 20px 5px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0px 0px 20px 5px rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 20px 5px rgba(255, 255, 255, 1);
}

.red {
  color: red;
}
.rev {
  border: 2px solid red;
}

.black {
  color: #333333;
}

.white {
  color: white;
}
.white-bg {
  background-color: white;
}

.orange {
  color: #ffa501;
}

/*----------------------------------------------------------------------*/
/*Social*/
/*----------------------------------------------------------------------*/

#socialContent {
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

@media screen and (max-width: 700px) {
  #socialContent {
    padding-top: 0px;
  }
}

.badge.social {
  background: #ccc;
  font-size: 24px;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 42px;
  margin: 0px 5px 20px 0;
  padding: 0;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  color: white;
}

.badge.social.twitter:hover {
  background: #55acee;
}

.badge.social.facebook:hover {
  background: #3b5998;
}

.badge.social.gplus:hover {
  background: #db4b39;
}

/*----------------------------------------------------------------------*/
/*ANIMATIONS*/
/*----------------------------------------------------------------------*/

/*animate elements*/

.animation-element {
  opacity: 0;
  position: relative;
}

/*animate element to slide down*/
.animation-element.slide-down {
  opacity: 1;
  -moz-transition: all 600ms linear;
  -webkit-transition: all 600ms linear;
  -o-transition: all 600ms linear;
  transition: all 600ms linear;
  -moz-transform: translate3d(0px, -100px, 0px);
  -webkit-transform: translate3d(0px, -100px, 0px);
  -o-transform: translate(0px, -100px);
  -ms-transform: translate(0px, -100px);
  transform: translate3d(0px, -100px, 0px);
}

.animation-element.slide-down.in-view {
  opacity: 1;
  -moz-transform: translate3d(0px, 0px, 0px);
  -webkit-transform: translate3d(0px, 0px, 0px);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

/*animate element to slide left*/
.animation-element.slide-left {
  opacity: 0;
  -moz-transition: all 600ms linear;
  -webkit-transition: all 600ms linear;
  -o-transition: all 600ms linear;
  transition: all 600ms linear;
  -moz-transform: translate3d(-100px, 0px, 0px);
  -webkit-transform: translate3d(-100px, 0px, 0px);
  -o-transform: translate(-100px, 0px);
  -ms-transform: translate(-100px, 0px);
  transform: translate3d(-100px, 0px, 0px);
}

.animation-element.slide-left.in-view {
  opacity: 1;
  -moz-transform: translate3d(0px, 0px, 0px);
  -webkit-transform: translate3d(0px, 0px, 0px);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

/*animate element to slide right*/
.animation-element.slide-right {
  opacity: 0;
  -moz-transition: all 600ms linear;
  -webkit-transition: all 600ms linear;
  -o-transition: all 600ms linear;
  transition: all 600ms linear;
  -moz-transform: translate3d(100px, 0px, 0px);
  -webkit-transform: translate3d(100px, 0px, 0px);
  -o-transform: translate(100px, 0px);
  -ms-transform: translate(100px, 0px);
  transform: translate3d(100px, 0px, 0px);
}

.animation-element.slide-right.in-view {
  opacity: 1;
  -moz-transform: translate3d(0px, 0px, 0px);
  -webkit-transform: translate3d(0px, 0px, 0px);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

/*animate element to slide up*/
.animation-element.slide-up {
  opacity: 1;
  -moz-transition: all 600ms linear;
  -webkit-transition: all 600ms linear;
  -o-transition: all 600ms linear;
  transition: all 600ms linear;
  -moz-transform: translate3d(0px, 100px, 0px);
  -webkit-transform: translate3d(0px, 100px, 0px);
  -o-transform: translate(0px, 100px);
  -ms-transform: translate(0px, 100px);
  transform: translate3d(0px, 100px, 0px);
}

.animation-element.slide-up.in-view {
  opacity: 1;
  -moz-transform: translate3d(0px, 0px, 0px);
  -webkit-transform: translate3d(0px, 0px, 0px);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

/*animate element to appear*/
.animation-element.appear {
  opacity: 0;
  -moz-transition: all 1000ms linear;
  -webkit-transition: all 1000ms linear;
  -o-transition: all 1000ms linear;
}

.animation-element.appear.in-view {
  opacity: 1;
}
/*animate elements*/

.animation-element {
  opacity: 0;
  position: relative;
}

/*animate element to slide down*/
.animation-element.slide-down {
  opacity: 1;
  -moz-transition: all 600ms linear;
  -webkit-transition: all 600ms linear;
  -o-transition: all 600ms linear;
  transition: all 600ms linear;
  -moz-transform: translate3d(0px, -100px, 0px);
  -webkit-transform: translate3d(0px, -100px, 0px);
  -o-transform: translate(0px, -100px);
  -ms-transform: translate(0px, -100px);
  transform: translate3d(0px, -100px, 0px);
}

.animation-element.slide-down.in-view {
  opacity: 1;
  -moz-transform: translate3d(0px, 0px, 0px);
  -webkit-transform: translate3d(0px, 0px, 0px);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

/*animate element to slide left*/
.animation-element.slide-left {
  opacity: 0;
  -moz-transition: all 600ms linear;
  -webkit-transition: all 600ms linear;
  -o-transition: all 600ms linear;
  transition: all 600ms linear;
  -moz-transform: translate3d(-100px, 0px, 0px);
  -webkit-transform: translate3d(-100px, 0px, 0px);
  -o-transform: translate(-100px, 0px);
  -ms-transform: translate(-100px, 0px);
  transform: translate3d(-100px, 0px, 0px);
}

.animation-element.slide-left.in-view {
  opacity: 1;
  -moz-transform: translate3d(0px, 0px, 0px);
  -webkit-transform: translate3d(0px, 0px, 0px);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

/*animate element to slide right*/
.animation-element.slide-right {
  opacity: 0;
  -moz-transition: all 600ms linear;
  -webkit-transition: all 600ms linear;
  -o-transition: all 600ms linear;
  transition: all 600ms linear;
  -moz-transform: translate3d(100px, 0px, 0px);
  -webkit-transform: translate3d(100px, 0px, 0px);
  -o-transform: translate(100px, 0px);
  -ms-transform: translate(100px, 0px);
  transform: translate3d(100px, 0px, 0px);
}

.animation-element.slide-right.in-view {
  opacity: 1;
  -moz-transform: translate3d(0px, 0px, 0px);
  -webkit-transform: translate3d(0px, 0px, 0px);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

/*animate element to slide up*/
.animation-element.slide-up {
  opacity: 1;
  -moz-transition: all 600ms linear;
  -webkit-transition: all 600ms linear;
  -o-transition: all 600ms linear;
  transition: all 600ms linear;
  -moz-transform: translate3d(0px, 100px, 0px);
  -webkit-transform: translate3d(0px, 100px, 0px);
  -o-transform: translate(0px, 100px);
  -ms-transform: translate(0px, 100px);
  transform: translate3d(0px, 100px, 0px);
}

.animation-element.slide-up.in-view {
  opacity: 1;
  -moz-transform: translate3d(0px, 0px, 0px);
  -webkit-transform: translate3d(0px, 0px, 0px);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

/*animate element to appear*/
.animation-element.appear {
  opacity: 0;
  -moz-transition: all 1000ms linear;
  -webkit-transition: all 1000ms linear;
  -o-transition: all 1000ms linear;
}

.animation-element.appear.in-view {
  opacity: 1;
}
body {
  background-image: url("../public/img/generic-bg.jpg");
  background-color: #333;
}
#banner {
  min-height: 100vh;
  background-color: #df8b00;
  background-image: url("../public/img/landing.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 50%;
  background-size: cover;
}
@media (max-width: 1175px) {
  #banner {
    /* background-image: url("../public/img/landingMin.jpg"); */
    background-position-x: 50%;
    background-position-y: 0%;
  }
}
@media (max-width: 425px) {
  #banner {
    /* background-image: url("../public/img/landingMin.jpg"); */
  }
}
@media (min-width: 600px) {
  .modal-content {
    padding-left: 50px;
    padding-right: 50px;
    font-size: 1.5em;
    font-family: Comfortaa, cursive;
  }
}
@media (max-width: 600px) {
  .modal-content {
    padding-left: 50px;
    padding-right: 50px;
    font-size: 1.2em;
    font-family: Comfortaa, cursive;
  }
}
@media (max-width: 500px) {
  .modal-content {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 1em;
    font-family: Comfortaa, cursive;
  }
}
.inline {
  display: inline-block;
}
.centered {
  text-align: center;
}
.tm-l {
  margin-top: 20vh;
}
.btn {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.28);
}
html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  margin: 0;
}
header {
  display: block;
}
button,
input,
textarea {
  margin: 0;
  font: inherit;
  color: inherit;
}
button {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}
input {
  line-height: normal;
}
textarea {
  overflow: auto;
}
*,
:after,
:before {
  box-sizing: border-box;
}
html {
  font-size: 10px;
}
body {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
}
button,
input,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
h4 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
}
p {
  margin: 0 0 10px;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}
.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #999;
}
.form-control::-webkit-input-placeholder {
  color: #999;
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-group {
  margin-bottom: 15px;
}
.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background-image: none;
  border-left: 3px solid transparent;
}
.btn-warning {
  color: #fff;
  background-color: #333;
  border-color: #eea236;
}
.fade {
  opacity: 0;
}
.navbar-toggle {
  position: relative;
  float: right;
  padding: 9px 10px;
  margin-top: 0;
  margin-right: 15px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
@media (min-width: 768px) {
  .navbar-toggle {
    display: none;
  }
}
.navbar-default .navbar-toggle {
  border-color: #ddd;
}
.close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.2;
}
button.close {
  -webkit-appearance: none;
  padding: 0;
  background: 0 0;
  border: 0;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.modal.fade .modal-dialog {
  -webkit-transform: translateY(-25%);
  transform: translateY(-25%);
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}
.modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}
.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}
.modal-header .close {
  margin-top: -2px;
}
.modal-title {
  margin: 0;
  line-height: 1.42857143;
}
.modal-body {
  position: relative;
  padding: 15px;
}
@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
}
.container:after,
.container:before,
.modal-header:after,
.modal-header:before {
  display: table;
  content: " ";
}
.container:after,
.modal-header:after {
  clear: both;
}
@-ms-viewport {
  width: device-width;
}
